<template>
  <div>
    <!-- Loading -->
    <div class="loading-template" v-if="loading">
      <loading-indicator />
    </div>
    <!-- Header -->
    <main-header />
    <!--  -->
    <div class="container">
      <!-- Breadcrumb -->
      <div class="_flex _center">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb  bg-transparent tiny">
            <li class="breadcrumb-item"><a href="#">Accueil</a></li>
            <li class="breadcrumb-item active" aria-current="page">Artistes</li>
          </ol>
        </nav>
      </div>
    </div>
    <!--  -->
    <section class="d-none d-lg-block my-3 my-4-lg">
      <div class="container">
        <ul v-if="allCategories && allCategories.length > 0" class="_flex _center _wrap list-unstyled body-2 medium mb-5-md mb-7-lg" id="facets">
          <li @click="getArtists" class="mb-3" style="background: rgba(0,0,0,.5)">
            <a class="c-pointer">
              <h2 class="text-capitalize text-black">Toutes</h2>
            </a>
          </li>
          <li class="mb-3" v-for="category in allCategories" :key="category.category_id">
            <a @click="filterArtistByCategory(category.category_id)" class="c-pointer">
              <img width="100" height="60" class="img-responsive img-tint" :src="category.img_src" alt=""/>
              <h2 class="text-capitalize">{{ category.name }}</h2>
            </a>
          </li>
        </ul>
      </div>
    </section>

    <!--  -->
    <div class="d-none d-lg-block p-2 border-bottom">
      <div class="container">
        <ul class="_flex _center body-1 text-muted list-unstyled">
          <li class="_flex mr-5 mr-10-lg mr-12-xl">
            Catégorie : <strong class="text-capitalize">{{ selectedCategory ? selectedCategory.name : 'Toutes' }}</strong>
          </li>
          <li class="_flex ">{{ this.artists ? this.artists.length : 0 }} résultat{{ (this.artists ? this.artists.length : 0) > 1 ? "s" : "" }}</li>
        </ul>
      </div>
    </div>
    <!--  -->
    <section class="container d-block d-lg-none">
      <div class="_flex _between subheading">
        <div class="bg-black mr-2 w-100 p-3 text-center">Trier</div>
        <div class="bg-black ml-2 w-100 p-3 text-center">Filtrer</div>
      </div>
    </section>
    <!--  -->
    <div class="container px-15-lg px-20-xl">
      <div class=" my-10">
        <!-- <div class="row d-flex flex-row flex-wrap _center my-10"> -->
        <div class="my-10">
          <div class="row" v-if="artists && artists.length">
            <div class="col-12 col-md-6 col-lg-4" v-for="artist in artists" :key="artist.user_id">
              <artist-card :artist="artist"/>
            </div>
          </div>
          <template v-else>
            <div class="d-flex w-100 flex-column align-items-center my-20">
              <img src="../assets/images/no_result_search.svg" alt="" style="height:180px;width:180px;object-fit:contain"/>
              <br />
              <h4 style="max-width:400px;text-align:center;font-weight:600">Aucun artiste trouvé</h4>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!--  -->
    <main-footer />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({
    loading: false,
    keyword: "",
    artists: null,
    allCategories: null,
    selectedCategory: null,
    count: 0,
  }),
  computed: {
    ...mapGetters([
      "getToken",
      "getUserData",
    ]),
  },
  watch: {},
  methods: {
    ...mapMutations([]),
    ...mapActions([
        "axiosGetAllData",
        "axiosGetOneData",
    ]),
    filterArtistByCategory(category_id) {
      this.loading = true
      this.selectedCategory = this.allCategories.filter( category => category.category_id === category_id )[0]
      this.axiosGetOneData({table: "retrieveArtistsByCategory", table_id: category_id})
          .then( response => {
            if(response.status === 200){
              this.artists = response.data.data
              /*this.$toast.success(response.data.message, {
                icon: "fas fa-2x fa-check-circle",
              })*/
            }else {
              this.artists = []
              /*this.$toast.warning(response.data.message, {
                icon: "fas fa-2x fa-exclamation",
              })*/
            }
            this.loading = false;
          })
          .catch()
    },
    getCategories() {
      this.loading = true;
      this.axiosGetAllData({table: "retrieveCategories"})
          .then( response => {
            if(response.status === 200){
              this.allCategories = response.data.data
            }
            this.loading = false;
          })
          .catch()
    },
    getArtists(){
      this.loading = true;
      this.axiosGetAllData({table: "retrieveArtists"})
          .then( response => {
            if(response.status === 200){
              this.artists = response.data.data
              /*this.$toast.success(response.data.message, {
                icon: "fas fa-2x fa-check-circle",
              })*/
            }else {
              this.artworks = []
              /*this.$toast.warning(response.data.message, {
                icon: "fas fa-2x fa-exclamation",
              })*/
            }
            this.loading = false;
          })
          .catch()
    }
  },
  mounted() {
    // When the user scrolls the page, execute myFunction

    window.onscroll = function() {
      scrollFunction();
    };

    //Get the button:
    var mybutton = document.getElementById("myBtn");

    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position

    // When the user scrolls down 20px from the top of the document, show the button

    function scrollFunction() {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    }

    this.keyword = this.$route.query.category;
  },
  created() {
    this.getCategories()
    this.getArtists()
  }
};
</script>

<style></style>
